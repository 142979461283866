<template>
  <div v-if="sceneType === 'training'">
    <div class="font-bold pdg-lr10">{{ item.codeMoney }}积分{{ item.count }}个</div>
  </div>
  <el-button v-else type="text" @click="onClick">
    <div class="code-label-box" :class="{selected:item.isSelected}">
      <div class="flex-around">
        <div class="number" :class="{selectedNumber:item.isSelected}">
          <div v-if="showTitle">
            <div v-if="item.includeDelegation" class="font-bold pdg-b5">{{ item.projectId?'':'学术任务+' }}委托调研{{ item.projectId?'+项目':'' }}</div>
            <div v-else class="font-bold pdg-b5">学术任务</div>
            <div class="pdg-b5">{{ item.representValidDay || 0 }}天代表执行</div>
            <div>{{ item.validDay || 0 }}天医生学习</div>
          </div>
          <div v-else>
            <div class="font-bold pdg-b5" />
            <div class="pdg-b5">{{ item.representValidDay || 0 }}天代表执行</div>
            <div>{{ item.validDay || 0 }}天医生学习</div>
          </div>
        </div>
        <div>{{ lessCount }}个</div>
      </div>
      <div v-if="item.isSelected && sceneType !== 'training'">
        <div class="code-radius" />
        <i class="el-icon-check top-left" />
      </div>
    </div>
  </el-button>
</template>

<script>
export default {
  name: 'CodeLabel',
  props: {
    item: {
      type: Object,
      default() {
        return {}
      }
    },
    lessCount: {
      type: Number,
      default: 0
    },
    index: {
      type: Number,
      default: 0
    },
    sceneType: {
      type: String,
      default: ''
    },
    showTitle: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  methods: {
    onClick() {
      this.$emit('onClick', this.item, this.index)
    }
  }
}
</script>

<style scoped>
.code-label-box {
  min-width: 140px;
  height: 71px;
  background: #fff;
  color: #7784a1;
  border: 1px solid #e9eaeb;
  border-radius: 4px;
  position: relative;
}
.left {
  left: 0;
}
.right {
  right: 0;
}
.code-radius {
  position: absolute;
  width: 0;
  height: 0;
  top: 0;
  right: 0;
  border-top: 20px solid #3d61e3;
  border-left: 20px solid transparent;
}
.top-left {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10000;
  color: #fff;
  font-weight: bold;
  font-size: 10px;
}
.code-label-content {
  line-height: 70px;
}
.selected {
  background: #fff;
  color: #3d61e3;
  border: 1px solid #3d61e3;
}
.number {
  border-right: 1px dashed rgba(233, 234, 235, 1);
  padding: 0 2px;
  height: 70px;

  /* 垂直居中 */
  display: flex;
  justify-content: center;
  align-items: center;
}
.selectedNumber {
  border-right: 1px dashed #3d61e3;
}
.code-item {
  padding-top: 20px;
}
</style>
